 /* @import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@500&display=swap'); */

 .contribution_popup{
  background-image: url(
      "");
}
.contribution_popup {
  position: relative;
  z-index: 0;
  /* width: 400px;
  height: 300px; */
  border-radius: 10px;
  overflow: hidden;
  padding: 0rem;
}
.contribution_popup::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  background-color: #399953;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(#399953, #399953), linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33), linear-gradient(#377af5, #377af5);
  -webkit-animation: rotate 4s linear infinite;
          animation: rotate 4s linear infinite;
}
.contribution_popup::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 6px;
  top: 6px;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  background-color: #e5eff3;
  border-radius: 5px;

}
 .adiv {
  background: #354b9c;
border-radius: 15px;
border-bottom-right-radius: 0;
border-bottom-left-radius: 0;
font-size: 12px;
height: 46px;

}

img {
  cursor: pointer
}

.fas {
  cursor: pointer
}

.fa-chevron-left {
  color: #fff
}

.remarkInput {
  border: none;
/* background: #F6F7FB; */
font-size: 12px
}

.remarkInput:focus {
  box-shadow: none;
background: #F6F7FB
}

.remarkInput ::placeholder {
  font-size: 12px;
color: #B8B9BD
}

.btn-main {
  width: 100px;
background: #0063FF;
font-size: 16px;
padding: 10px;
margin: 10px;
color: white;
border: none;
border-radius: 10px;
}

.btn-main:focus {
  box-shadow: none
}

.btn-main span {
  font-size: 12px;
color: #A6DCFF
}

.main-card {
  background: #EEEEEE;
font-family: 'Hind Siliguri', sans-serif;
width: 600px;
}

.for-img {
  width: 130px;
height: 130px;
border-radius: 50%;
border: 1px solid cornflowerblue;
margin: auto;
/* background-color: red; */
background-image: url('../../../images/dineshimg.png');
background-repeat: no-repeat;
background-position: center;
}
.btnOne{
  border-radius: 50%;
height: 60px;
width: 60px;
}
.form__group {
  position: relative;
padding: 15px 0 0;
margin-top: 10px;
/* width: 50%; */
}

.form__field {
  font-family: inherit;
width: 100%;
border: 0;
border-bottom: 2px solid #9b9b9b;
outline: 0;
font-size: 1.3rem;
color: #6f4e37;
padding: 7px 0;
background: transparent;
transition: border-color 0.2s;
}
.form__field::placeholder {
  color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
  font-size: 1.3rem;
cursor: text;
top: 20px;
}

.form__label {
  position: absolute;
top: 0;
display: block;
transition: 0.2s;
font-size: 1rem;
color: #9b9b9b;
margin:auto;
}

.form__field:focus {
  padding-bottom: 6px;
font-weight: 700;
border-width: 3px;
border-image: linear-gradient(to right, #11998e, #38ef7d);
border-image-slice: 1;
}
.form__field:focus ~ .form__label {
  position: absolute;
top: 0;
display: block;
transition: 0.2s;
font-size: 1rem;
color: #11998e;
font-weight: 700;
}

/* reset input */
.form__field:required, .form__field:invalid {
  box-shadow: none;
}