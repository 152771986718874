
.dashboard-header{
    background: rgb(53,75,156);
    background: linear-gradient(150deg, #f57729 37%, rgb(78, 89, 240) 100%);
    color:white !important;
    border-radius: 8px;
    /* height: 60px; */
    margin-top: -19px;
    /* padding-top: 7px; */
    padding: 10px;
  

}
.dashboard-header h2{
    color: white;
}
.dashbord-boby{
    padding: 0.5rem;
    margin: 1rem !important;
   
    margin-right: 1rem !important;
}
.mrf-card-body{
    background-color: white;
    margin: 10px;
    border-radius: 8px;
    font-size:smaller;
    padding: 0;
    margin: 0;
        /* height: 150px; */
}
.mrf-card-body .col-md-6{
    padding: 10px!important;
}
.vacancyBtn{
    padding: 6px 0px;
}
.vacancyBtn h6{
 font-size: 20px;
 font-weight: 700;
 color: #fff;
 margin: 0;
}
.left-section{
    /* background: rgb(53,75,156);
    background: linear-gradient(90deg, rgba(53,75,156,1) 37%, rgba(236,39,144,1) 100%); */
    background-color: lightgray;
    color: white;
    border-radius: 8px;
    text-align: center;
    /* justify-content: center;
    vertical-align: middle;
    align-items: center; */
    /* text-align: center; */
   width: 100%;
    padding: 5px;
    height: 100%;
   
    

}
.right-section{
    justify-content: center;
    display: flex;
    text-align: center;
  
    padding: 5px;

}
.vacancy-btn{
   
    border: 2px;
    border-color: black;
    background-color: #354b9c;
    color:white ;
    border-radius: 8px;
    text-align: center;
   

 
 
}
.vacancy-btn h5{
    color: white;
}
.replacement-btn{
    border: 2px;
    border-color: black;
    background-color: #354b9c;
    color:white;
    border-radius: 8px;
    text-align: center;


}
.replacement-btn h5{
    color: white;
}
.performance-card{
    min-height: 500px;
    

}
.recruiters-card{
    min-height: 500px;
  
}
.performance-no{
    background-color: #354b9c;
    border-radius: 8px;
    color: white;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 26px;
    margin-right: 70px;
}
.tat-no{
    
    color: rgb(12, 1, 1);
    justify-content: center;
    display: flex;
    text-align: center;
    margin-top: 14px;
}
.p-card{
    background-color: #EBEDEF !important;
}
.performance-header h5{
    color:black;
    text-align: center;
}
.card-body .performance-card-body{
    min-height: 116px;
}

.btn .header-btn{
    background-color: white !important;
}


.performance-card-body h1{
    color: #f57729;
}