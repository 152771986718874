.nameDashbod{
    color: #232831;
}
.nameDashbod strong{
    color: #F47321;
}
.btnBox a{
    border: 1px solid #CFCFCF;
    border-radius: 4px;
    padding: 8px 12px;
    color: #B5B5B5;
    background-color: #fff;
  font-size: 12px;
  text-align: center;
  margin-left: 10px;
}
.btnRond{
    display: inline-flex;
    background-color: #354C9E;
    color: #fff !important;
    border-radius: 30px;
    padding: 8px 15px;
    text-align: center;
    font-weight: 600;
    font-size: 11px;
    align-items: center;
    justify-content: center;
    line-height: 15px;
}
.btnBox a.active, .btnBox a:hover{
    background-color: #354C9E;
    color: #fff;
    border-color: #354C9E;
}
.cardHdng{
    padding: 15px;
    border-radius: 2px;
}
.contentpre h2{
font-weight: 700;
color: #EB7C45;
font-size: 42px;
}
.lottieB{
  
    transform: rotate(-90deg);
}
.lottieC{
  
    transform: rotate(90deg);
}
.courseBox{
    width: 60%;
}
.contentBxx{
    width: 40%;
}
.contentpre h5{
    font-size: 24px;
    color: #354C9E;
    font-weight: 600;
    margin: 0;
}
.contentpre h6{
    color: #EB7C45;
    font-size: 16px;
    font-weight: 700;
}
.contentpre p, .courseBox p{
    color: #646D82;
    font-size: 11px;
    font-weight: 600;
}
.textRed{
    color: #FF0000;
    font-weight: 600 !important;
}
.textblue{
    color: #354C9E;
    font-weight: 600 !important;
}
.hdngMn{
    font-weight: 600;
}
.subHdngg{
    font-size: 12px;
    font-weight: 700;
    color: #323333;
    letter-spacing: 1px;
}
.barSec{
    display: flex;
    align-items: center;
    width: 100%;
   
}
.barSec span{
    color:#354C9E;
    font-weight: 600 !important;
    font-size: 9px;
    flex-shrink: 0;
    margin-left: 7px;
}
.barBox{
    height: 32px;
    color: #fff;
    padding-left: 15px;
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
    border: 1px solid #fff;

}
.barSecmn{
    width: 65%;
}

.contentpre2{
    width: 35%;
}
.linkOption .c-avatar p{
font-size: 12px;
}
.linkOption .c-avatar p span{
    color: #354C9E;
    font-weight: 600 !important;
}
.linkOption .c-avatar {
    justify-content: left;
    width: auto;
}
.linkOption strong{
    color: #323333 !important;
    font-weight: 500 !important;
    font-size: 12px;
}
.linkOption .dropdown-item{
padding: 5px 10px;
}
.countValu span{
width: 9px;
border-radius: 2px;
height: 9px;
margin-right: 5px;
display: inline-block;

}
.countValu p{
    font-size: 10px;
    color: #646D82;
    margin-left: 15px;
}
.btnTag{
    letter-spacing: 1px;
    background: rgb(235,124,69);
    background: linear-gradient(90deg, rgba(235,124,69,1) 0%, rgba(235,174,144,1) 100%);
    border-radius: 2px;
    font-weight: 600 !important;
    font-size: 12px;
    color: #fff;
    padding: 3px 20px;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);

    position: absolute;
    left: -4%;

}
.grphBox{
    width: 75%;
}
.contentBxoxx{
    width: 25%;
    padding-left: 15px;
    border-left: 1px solid #ccc;
}
.countValu .bluB{
    background-color: #354C9E;
}
.countValu .gryB{
background-color: #E4E4E4;
}
.countValu .orgB{
    background-color: #EB7C45;
}
.countValu .grnB{
    background-color: #2CD889;
    }
    .countValu .redB{
        background-color: #F7617D;
    }
.courseBox .btnTag{
    left: -12%;
}
.linkOption .btn:focus{
    box-shadow: none !important;
}