/* body{
	margin: 0;
	padding: 0;
}
a {
    text-decoration: none;
} */

.main-div{
	margin: 0;
	padding: 0;
	background: #f1f1f1;
	font-family: 'Lato', sans-serif;
	font-size: 15px;
	line-height: 1.8;
	color: rgba(0,0,0,.5);
}
.line_rating {
    border: 1px solid;
    border-image-slice: 1;
    border-bottom: 5px;
    border-image-source: linear-gradient(
270deg,#354b9c,#ff0057);
}

.email-container{
	border-left: 10px solid;
    border-right: 10px solid;
	/* border: 10px solid; */
    border-image-slice: 1;
    border-width: 3px;
	background-repeat: no-repeat;
    border-image-source: linear-gradient(to left,#354b9c, #FF0057);
	background-image: url("../../../images/fav.png");
	background-position: center;
    background-repeat: no-repeat;
}