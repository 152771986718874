.h4{
    background: linear-gradient(125deg,#f56e2a,#2a3c81);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    display: block;
}

.tableth1{
    width: 35%;
    border: none;
}
.tabletd1{
    width: 65%;
    border: none;
}


.otp-spacing {
    width: 122px;
    margin-right: -6%;
  }
