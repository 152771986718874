a {
    text-decoration: none;
}

.main-div_aggregament_letter{
    background: #f1f1f1;
	font-family: 'Lato', sans-serif;
	font-size: 15px;
	line-height: 1.8;
	color: rgba(0,0,0,.5);
}

.line_rating {
    border: 1px solid;
    border-image-slice: 1;
    border-bottom: 5px;
    border-image-source: linear-gradient(
270deg,#354b9c,#ff0057);
}

.email-container{
	background-image: url("../../../images/favicon-hr.png");
	background-position: center;
    background-repeat: no-repeat;
	border-left: 10px solid;
    border-right: 10px solid;
    border-image-slice: 1;
    border-width: 3px;
	background-repeat: no-repeat;
    border-image-source: linear-gradient(to left,#354b9c, #FF0057);

}
.derssc {
	border: 1px solid;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.tabledata{
  border: 1px solid;
  text-align: left;
  padding: 8px;
  color: #000;
}
.tablehead{
  border: 1px solid;
  text-align: left;
  padding: 8px;
  color: #000;
}
.li{
	padding: 1.5em;
}