.input-group-text {
  width: 80px;
  font-weight: bold;
  padding: 6px;
  text-align: center;
  color: white;
  border: none;
  content: "\2192";

}

.custome-container {
  min-height: calc(100vh - 92px);
  display: flex;
  background: #000;
  align-items: normal;
  flex-direction: column;
  justify-content: space-evenly;
  
} 



.Screen-main {
  width: 100%;
  /* border: 3px solid rgb(255, 255, 255); */
}

.img-brcode {
  height: 400px;
  border: 2px solid rgb(4, 8, 241);
}
.bg-icon {
  background: linear-gradient(#ff3399, #333399);
  /* background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%); */
  /* background-color: #ff3399,#333399; */
}

@media(max-width:768px){
  .custome-container{
    /* padding: 0px 0px 15px 15px; */
  }
}