.q-box{
    color:#f1f1f1;
    font-weight: bold; 
}
.q-body{
    min-height: 400px !important;
}
.feedback-body{
    display: flex;
    justify-content: center;
}
.onboarding_btn{
    margin-top: 100px;

}
.question-body{
    min-height: 500px !important;
    height: auto;
   
}
.newsurvey-container{
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  height: auto;
  background-color: #b8c6db;
background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%);
}
.newsurvey-containerQ{
    background-color: #ffffff;
    color: #f1f1f1;
background-image: linear-gradient(315deg, #354b9c 0%, #1b2845 30%,#356 20%);

    position: relative;
  }
.newsurvey-card{
    background: rgba(255,255,255,.4);
    padding-bottom: 2vh;
}
 .server-emoji{   
        font-size: 50px;
        /* position: absolute;
        padding-right: 490px;
        padding-top: 142px;
        padding-left: 91px; */
        position: relative;
        bottom: 97px;

 }
