body {
      font-family: 'Roboto Flex', sans-serif;
      font-size: 14px;
  }
  
  ul {
      margin: 0;
      padding: 0;
      list-style: none;
  }
  
  a {
      text-decoration: none;
  }
  
  
  .fs-7 {
      font-size: 14px;
  }
  
  .fs-8 {
      font-size: 12px;
  }
  
  .fs-9 {
      font-size: 13px;
  }
  
  .text-danger {
      color: #FF0000 !important;
  }
  
  .btn-primary {
      font-size: 13px;
      background: #354C9E !important;
      border: 1px solid #354C9E !important;
      border-radius: 100px;
      padding: 8px 20px;
  }
  
  .text-primary {
      color: #354C9E !important;
  }
  
  .btn-orange {
      color: #fff !important;
      background-color: #F47320 !important;
      font-size: 13px;
      border: 0;
      padding: 6px 15px;
      border-radius: 4px;
      display: inline-block;
      text-align: center;
  }
  
  .btn-outline-secondary {
      color: #C0C0C0;
      background-color: #fff;
      font-size: 12px;
      border: 1px solid #C0C0C0;
      padding: 6px 10px;
      border-radius: 4px;
      min-width: 125px;
      text-align: center;
  }
  
  .btn-outline-orange {
      color: #F47320;
      background-color: #fff;
      font-size: 12px;
      border: 1px solid #F47320;
      padding: 6px 15px;
      border-radius: 4px;
      min-width: 110px;
      height: 34px;
      border-radius: 6px;
      font-weight: 600;
      display: inline-block;
      text-align: center;
  }
  
  .text-secondary {
      color: #858997;
  }
  
  .text-orange {
      color: #F47320 !important;
  }
  
  .bg-primary {
      background-color: #00407E !important;
  }
  
  .bg-orange {
      background-color: #F47320 !important;
  }
  
  .bg-light-orange {
      background-color: #FBBF98 !important;
  }
  
  .bg-secondary {
      background-color: #CCCCCC !important;
  }
  
  .cardBox {
      box-shadow: 0px 0px 6px #0000001A;
      border-radius: 8px;
      padding: 15px;
      background: #fff;
  }
  
  .container-fluid {
      padding-left: 20px;
      padding-right: 20px;
  }
  
  
  .headerMain {
      background: #fff;
      padding: 15px 0;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.14);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 999;
  }
  
  .profileIMG {
      width: 34px;
      height: 34px;
      border-radius: 100px;
      object-fit: cover;
      margin-left: 9px;
  }
  
  .profileDropdown .dropdownToggle {
      font-size: 13px;
      font-weight: 500;
      color: #000;
  }
  
  .profileDropdown .dropdown-menu {
      border: 1px solid #f5f5f5;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.14);
      padding: 0;
  }
  
  .profileDropdown .dropdown-menu>li>a {
      font-size: 14px;
      padding: 10px 14px;
      border-bottom: 1px solid #f5f5f5;
  }
  
  .dashboardMain {
      padding-top: 68px;
  }
  
  .sideBar {
      position: fixed;
      top: 67px;
      left: 0;
      width: 60px;
      height: calc(100% - 67px);
      z-index: 990;
      background: #fff;
      transition: .3s all;
      overflow: hidden;
  }
  
  
  
  .sideMenu {
      position: relative;
      height: 100%;
  }
  
  .sidebarToggle {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 1;
      background: rgba(0, 0, 0, 0.2);
      padding: 10px;
      display: block;
      border: none;
      color: #fff;
      text-align: center;
      font-size: 20px;
      outline: none;
  }
  
  .sideMenuList>li>a {
      display: flex;
      align-items: center;
      padding: 15px 20px;
      border-bottom: 1px solid #f5f5f5;
      transition: .1s all;
      color: #000;
      font-weight: 500;
      min-height: 52px;
      white-space: nowrap;
  }
  
  .sideMenuList>li ul {
      padding: 10px 0 10px 60px;
  }
  
  .sideMenuList>li ul li {
      padding: 5px 0;
  }
  
  .sideMenuList>li ul li a {
      color: #000;
      font-weight: 500;
      display: block;
  
  }
  
  .sideMenuList>li ul li a:hover {
      color: #F47320;
  }
  
  .sideBar.openMenu .sideMenuList>li>a.collapseToggle {
      padding-right: 35px;
      position: relative;
  }
  
  .sideBar.openMenu .sideMenuList>li>a.collapseToggle:after {
      content: "\F285";
      position: absolute;
      right: 10px;
      top: 15px;
      font-family: bootstrap-icons !important;
      transform: rotate(90deg);
      transition: .3s all;
  }
  
  .sideBar.openMenu .sideMenuList>li>a.collapseToggle[aria-expanded="true"]:after {
      transform: none;
  }
  
  .sideBar:not(.openMenu) .sideMenuList>li .cstmCollapse {
      display: none !important;
      height: 0 !important;
  }
  
  .sideMenuList>li>a img {
      transition: .2s all;
      margin-right: 30px;
  }
  
  .sideMenuList>li>a:hover {
      background: #eb7c45;
      color: #fff;
  }
  
  .sideMenuList>li>a:hover img {
      filter: invert(1);
  }
  
  .sideBar.openMenu {
      width: 280px;
      font-size: 13px;
  }
  
  .sideBar.openMenu .sidebarToggle {
      transform: rotate(180deg);
  }
  
  .sideBar.openMenu .sideMenuList {
      height: calc(100% - 50px);
      overflow: auto;
  }
  
  .sideMenuList::-webkit-scrollbar {
      width: 5px;
  }
  
  .sideMenuList::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  
  .sideMenuList::-webkit-scrollbar-thumb {
      background-color: darkgrey;
  
  }
  
  .sideBar.openMenu .sideMenuList>li>a {
      display: block;
      text-align: left;
  }
  
  .sideBar.openMenu .sideMenuList>li>a img {
      margin-right: 15px;
  }
  
  .menuOverlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.44);
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      transition: .3s all;
  }
  
  .menuOverlay.show {
      opacity: 1;
      visibility: visible;
  }
  
  .teamList {
      border: none;
      height: calc(100% - 37px);
      overflow: auto;
  }
  
  .teamList::-webkit-scrollbar {
      width: 5px;
  }
  
  .teamList::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  
  .teamList::-webkit-scrollbar-thumb {
      background-color: darkgrey;
  
  }
  
  .teamList>li {
      display: block;
      width: 100%;
      padding: 6px 16px;
  }
  
  .teamList>li>a {
      display: block;
      background: #fff;
      padding: 13px 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
      border-radius: 4px;
      color: #000;
      font-size: 13px;
      display: flex;
      align-items: center;
      font-weight: 500;
      transition: .3s all;
  }
  
  .teamList>li>a img {
      margin-right: 10px;
  }
  
  .teamList>li>a:hover {
      background: #fff2e9;
  }
  
  .teamList>li>a.active {
      background: #F47320;
      color: #fff;
  }
  
  .teamList>li>a strong {
      margin-left: auto;
  }
  
  .teamList>li>a strong b {
      font-size: 11px;
  }
  
  .dashboardContent {
      padding-left: 60px;
  }
  
  .temsSidebar {
      width: 280px;
      position: sticky;
      top: 67px;
      left: 60px;
      background: #f5f5f5;
      height: 100vh;
      height: calc(100vh - 68px);
      padding: 15px 0;
  }
  
  .temsSidebar h2 {
      font-size: 18px;
      font-weight: 700;
      margin: 0;
  }
  
  .teamContent {
      width: calc(100% - 280px);
      padding: 15px;
  }
  
  .toggle-label {
      position: relative;
      display: block;
      width: 86px;
      height: 26px;
      background: #D3D3D3;
      border-radius: 100px;
      overflow: hidden;
  }
  
  .toggle-label input[type=checkbox] {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
  }
  
  
  
  .toggle-label input[type=checkbox]+.back .toggle {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      content: ' ';
      background: #F47320;
      width: 43px;
      height: 26px;
      transition: .15s all;
      border-radius: 0;
      border-radius: 100px;
  }
  
  .toggle-label input[type=checkbox]:checked+.back .toggle {
      left: 43px;
  }
  
  .toggle-label .label {
      display: block;
      position: absolute;
      width: 50%;
      text-align: center;
      font-size: 12px;
      line-height: 25px;
      cursor: pointer;
  }
  
  .toggle-label .label.on {
      left: 0px;
  }
  
  .toggle-label .label.off {
      right: 0px;
  }
  
  .toggle-label input[type=checkbox]+.back .label.on {
      color: #fff;
  }
  
  .toggle-label input[type=checkbox]:checked+.back .label.on {
      color: #000;
  }
  
  .toggle-label input[type=checkbox]+.back .label.off {
      color: #000;
  }
  
  .toggle-label input[type=checkbox]:checked+.back .label.off {
      color: #fff;
  }
  
  .showTeam {
      font-size: 12px;
      text-decoration: underline;
      color: #F47320;
      font-weight: 500;
      cursor: pointer;
      
  }
  
  .dashboardTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  
  .dashboardTitle span {
      font-size: 12px;
      color: #646464;
      display: block;
      margin-bottom: 5px;
  }
  
  .dashboardTitle span b {
      color: #000;
  }
  
  .dashboardTitle h1 {
      font-size: 22px;
      font-weight: 700;
  }
  
  .topfilter {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
  }
  
  .topfilter .form-control {
      height: 38px;
      font-size: 13px;
      box-shadow: none;
      outline: none;
      font-weight: 600;
      width: 200px;
  }
  
  .topfilter .form-control.dateInput {
      /* background: url(public/images/calendar.svg); */
      background-position: center left 15px;
      background-repeat: no-repeat;
      background-size: 20px;
      padding-left: 45px;
  }
  .selectTotal .dropdown-toggle{
      height: 38px;
      font-size: 13px;
      box-shadow: none;
      outline: none;
      font-weight: 600;
      width: 200px;
      border: 1px solid #dee2e6;
      display: block;
      border-radius: 4px;
      color: #212529;
      padding: 7px 30px 7px 15px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
  }
  .selectTotal .dropdown-toggle:after{
      content:"\F282";
      font-family: bootstrap-icons !important;
      border: none;
      position: absolute;
      right: 10px;
      top: 10px;
  }
  .selectTotal .dropdown-menu {
      width: 270px;
      border: none;
      padding:5px 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
  }
  .selectTotal .dropdown-menu>ul{
      max-height: 240px;
      overflow: hidden;
      overflow-y: auto;
  }
  .selectTotal .dropdown-menu>ul::-webkit-scrollbar {
      width: 5px;
  }
  
  .selectTotal .dropdown-menu>ul::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  
  .selectTotal .dropdown-menu>ul::-webkit-scrollbar-thumb {
      background-color: darkgrey;
  
  }
  .selectTotal .dropdown-menu>ul>li {
      padding: 4px 15px;
  }
  
  .selectTotal .dropdown-menu>ul>li>label {
      font-size: 13px;
      position: relative;
      padding-left: 20px;
      cursor: pointer;
  }
  .selectTotal .dropdown-menu>ul>li>label:before{
      content: "";
      position: absolute;
      top: 3px;
      left: 0;
      width: 14px;
      height: 14px;
      border: 1px solid #ccc;
  }
  .selectTotal .dropdown-menu>ul>li> input[type="checkbox"]:checked ~ label:before{
      content: " \F633";
      font-family: bootstrap-icons !important;
      line-height: 12px;
      font-size: 12px;
  }
  
  .plannedScore strong {
      font-size: 43px;
      color: #EB7C45;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  .plannedScore strong lottie-player {
      transform: rotate(-90deg);
  }
  
  .plannedScore span {
      font-size: 15px;
      text-align: center;
      display: block;
      line-height: 20px;
      color: #646D82;
      padding: 10px 0;
  }
  
  .plannedScore span b {
      color: #FF0000;
  }
  
  .versusTxt {
      border-top: 1px solid #D8EAE6;
      margin-top: 13px;
      padding-top: 13px;
      display: flex;
      justify-content: space-between;
      align-items: end;
      margin-bottom: 20px;
  }
  
  .versusTxt strong {
      font-size: 13px;
      color: #646D82;
      font-weight: 500;
      display: block;
      text-align: center;
  }
  
  .versusTxt strong b {
      display: block;
      font-size: 20px;
      color: #354C9E;
  }
  
  .versusTxt span {
      font-size: 26px;
      font-weight: 600;
      color: #EB7C45;
  }
  
  .innerScore {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 20px;
      color: #354C9E;
      font-weight: 700;
  }
  
  .innerScore sub {
      font-size: 14px;
      margin-bottom: 5px;
      bottom: 0;
  }
  
  .progress-stacked.leavesProgress {
      border-radius: 0;
      height: 45px;
  }
  
  .leavesProgress .progress {
      height: 45px;
      cursor: pointer;
      border-right: 2px solid #fff;
  }
  
  .leavesProgress .progress:last-child {
      border-right: none;
  }
  
  .leavesProgress .progress-bar.bg-orange {
      background: rgba(244, 115, 32, .5) !important;
  }
  
  .leavesProgress .progress-bar.bg-primary {
      background: rgba(48, 76, 159, .5) !important;
  }
  
  .leavesProgress .progress-bar.bg-info {
      background: rgba(121, 197, 251, .5) !important;
  }
  
  .leavesProgress .progress-bar.bg-pink {
      background: rgba(252, 145, 197, .5) !important;
  }
  
  .leavesProgress .active .progress-bar.bg-orange {
      background: rgba(244, 115, 32, 1) !important;
  }
  
  .leavesProgress .active .progress-bar.bg-primary {
      background: rgba(48, 76, 159, 1) !important;
  }
  
  .leavesProgress .active .progress-bar.bg-info {
      background: rgba(121, 197, 251, 1) !important;
  }
  
  .leavesProgress .active .progress-bar.bg-pink {
      background: rgba(252, 145, 197, 1) !important;
  }
  
  .leavesTxt {
      background: #F5F5F5;
      padding: 30px 15px;
      text-align: center;
  }
  
  .leavesTxt strong {
      display: block;
      font-size: 38px;
  }
  
  .leavesTxt span {
      display: block;
      font-size: 14px;
      font-weight: 600;
  }
  
  .leave-sick-leave {
      color: #F47320;
      /* Add any other styles for Sick Leave */
  }
  
  .leave-short-leave {
      color: #304C9F;
      /* Add any other styles for Short Leave */
  }
  
  .leave-paternity-leave {
      color: #79C5FB;
      /* Add any other styles for Paternity Leave */
  }
  
  .leave-maternity-leave {
      color: #FC91C5;
      /* Add any other styles for Maternity Leave */
  }
  
  .cstmLagend {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
  }
  
  .cstmLagend>li {
      font-size: 12px;
      font-weight: 600;
      padding-left: 15px;
      position: relative;
  }
  
  .cstmLagend>li:before {
      content: "";
      position: absolute;
      top: 6px;
      left: 0;
      width: 7px;
      height: 7px;
      border-radius: 100px;
  }
  
  .cstmLagend>li.sleave:before {
      background: #F47320;
  }
  
  .cstmLagend>li.shleave:before {
      background: #304C9F;
  }
  
  .cstmLagend>li.pleave:before {
      background: #79C5FB;
  }
  
  .cstmLagend>li.maleave:before {
      background: #FC91C5;
  }
  
  .parameterList {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
  }
  
  .parameterList>li {
      background: #F47320;
      padding: 5px 15px;
      border-radius: 100px;
      font-size: 12px;
      color: #fff;
      display: flex;
      align-items: center;
  }
  
  .parameterClose {
      border: none;
      background: none;
      padding: 0;
      color: #fff;
      font-size: 20px;
      line-height: 0;
      margin-left: 5px;
  }
  
  /* .overallTable .table {
      margin: 0;
  } */
  
  /* .overallTable .table tr th {
      font-size: 13px;
      font-weight: 600;
      background: #F5F5F5;
      border: none;
      padding: 10px 15px;
      width: 33.33%;
  } */
  
  /* .overallTable .table tr td {
      font-size: 12px;
      font-weight: 500;
      padding: 10px 15px;
      border-color: rgba(214, 217, 220, .4);
      width: 33.33%;
  } */
  
  /* .overallTable .table tr:last-child td {
      border: none;
  }
   */
  .avgCount {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
      height: 100px;
      border-radius: 100px;
      background: #fff;
      box-shadow: 0 0 14px rgba(0, 0, 0, 0.14);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #FE7B1E;
  }
  
  .avgLowerTxt lottie-player {
      transform: rotate(90deg);
  }
  
  .absentPresentTb {
      position: absolute;
      right: 2%;
      top: 30%;
      z-index: 1;
      border: none;
      display: block;
  }
  
  .absentPresentTb>li {
      padding: 10px 0;
  }
  
  .absentPresentTb>li button {
      background: none;
      border: none;
      font-size: 12px;
      font-weight: 600;
      position: relative;
      padding-left: 25px;
  }
  
  .absentPresentTb>li button:before {
      content: "";
      position: absolute;
      top: 2px;
      left: 0;
      width: 17px;
      height: 17px;
      border-radius: 4px;
      background: #CDD5E1;
  }
  
  .absentPresentTb>li:nth-child(1) button.active::before {
      background: #F47320;
  }
  
  .absentPresentTb>li:nth-child(2) button.active::before {
      background: #354C9E;
  }
  .teamsCount {
      background-color: #FFF5EE;
      padding: 10px 12px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      transition: .3s all;
  
  }
  
  .teamsCount:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
  
  }
  
  .teamsCount span {
      display: inline-block;
      font-weight: bold;
      font-size: 26px;
      border: 2px solid #F47320;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: #F47320;
      margin-bottom: 10px;
  }
  
  .teamsCount strong {
      color: #F47320;
      font-weight: 400;
      gap: 8px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
  }
  
  
  
  .allManagers span {
      color: #858997;
      font-size: 12px;
      padding-left: 6px;
      border-left: 4px solid #00407E;
      font-weight: 500;
  }
  
  .allManagers ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start !important;
  }
  
  /* .allManagers ul li{
      
  } */
  .allManagers ul li {
      margin-bottom: 8px;
      width: 25%;
  }
  
  .allManagers ul li img {
      height: 34px;
      width: 34px;
      object-fit: cover;
      border-radius: 50%;
      flex-wrap: wrap;
  
  }
  
  .summerychart {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      padding: 10px;
      line-height: 16px;
  }
  
  .summerychart {
      width: 100px;
      height: 100px;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 1;
  }
  
  .summeryman {
      width: 175px;
      position: relative;
  }
  
  .summerychart span {
      color: #C0C0C0;
      font-weight: 600;
      font-size: 12px;
      margin-bottom: 6px;
  
  }
  
  .summerychart strong {
      color: #F47320;
      font-size: 20px;
      font-weight: 800;
      margin-bottom: 4px;
  }
  
  
  .summerychart {
      color: #F47320;
      font-weight: 600;
      font-size: 13px;
  }
  
  .chartcountData {
      margin-bottom: 10px;
  }
  
  .chartcountData span {
      padding: 5px 7px;
      border: 1px solid #00407E;
      border-radius: 10px;
      font-size: 18px;
      font-weight: bold;
      color: #00407E;
      min-width: 40px;
      text-align: center;
  }
  
  .progressman span,
  .progressman strong {
      color: #858997;
      font-size: 12px;
      border: 0;
      padding: 0;
  
  }
  
  .progressman strong {
      color: #3E4146 !important;
  }
  
  .progressman {
      margin-left: 10px;
  }
  
  .progressman .progress {
      height: 10px;
  }
  
  .breakhours span {
      color: #F47320 !important;
      border-color: #F47320;
  }
  
  .leaves span {
      color: #FBBF98 !important;
      border-color: #FBBF98;
  }
  
  .Miscellanious span i {
      color: #ffffff;
  
  }
  
  .Miscellanious span {
      background-color: #CCCCCC;
      border: 0;
  }
  
  .searchinput {
      width: 0px;
      padding-left: 35px;
      padding-right: 6px;
      padding-top: 3px;
      padding-bottom: 4px;
      transition: all .5s;
      border: 1px solid #CFCFCF;
      border-radius: 5px;
      height: 34px;
  }
  
  .searcicon {
      position: absolute;
      left: 12px;
      top: 8px;
      pointer-events: none;
      width: 17px;
  }
  
  .searchinput:focus {
      outline: none;
      width: 300px;
  }
  
  .empNumber {
      background-color: #F47320;
      padding: 6px;
      color: #F47320;
      font-weight: 700;
      font-size: 20px;
      padding-right: 8px;
      padding-left: 20px;
      display: inline-block;
  }
  
  .empNumber {
      position: relative;
      background: #FFDFCA;
  }
  
  .empNumber:after {
      left: 100%;
      top: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-left-color: #FFDFCA;
      border-width: 22px;
      margin-top: -22px;
  }
  
  .attendanceContent h6 {
      font-size: 12px;
      color: #000000;
      margin-bottom: 0;
      font-weight: 800;
  }
  
  .attendanceContent span {
      color: #3e4146;
      font-size: 12px;
      font-weight: 500;
  }
  
  .attendanceStatus {
      border: 1px solid #dee2e6;
      border-radius: 6px;
  }
  
  /* .attendanceStatus table tr {
      padding: 10px 0px;
  } */
  
  /* .attendanceStatus table tr td {
      width: 20%;
      padding: 10px;
  } */
  
  .customPagination .recordTitle {
      margin-right: 30px;
      font-size: 12px;
      font-weight: 500;
      color: #979797;
  }
  
  .pagination {
      gap: 10px;
  }
  
  .pagination .active .page-link {
      background: #F47320;
      color: #fff;
  }
  
  .customPagination {
      margin: 10px 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
  }
  
  .pagination .page-link {
      border-radius: 50% !important;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 6px #00000029;
      border: 0;
  }
  
  .pagination .page-link {
      color: #F47320;
  }
  
  .attendanceStatus h2 {
      font-size: 18px;
      font-weight: 700;
  }
  
  .backBtn{
      font-size: 20px;
      color: #000;
      margin-right: 5px;
  }
  
  
  .attendancTbl tr td.empNumberCOunt{
      width: 100px;
      min-width: 100px;
  }
  
  .attendancTbl tr td:last-child {
      padding-left: 0;
      width: 100px;
      min-width: 100px;
  }
  
  /* .table tr td {
      text-wrap: nowrap;
      padding: 10px 17px;
      vertical-align: middle;
  } */
  
  /* .table-responsive::-webkit-scrollbar {
      width: 5px;
      height: 5px;
  } */
  
  /* .table-responsive::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   */
  /* .table-responsive::-webkit-scrollbar-thumb {
      background-color: darkgrey;
  
  } */