.card_box {
cursor: pointer;
height: 120px;
color: white;
}
.box_title{
color : black;
}

.card_height {
  background-color:"#ADD8E6";
   color:"white"; 
   height:"80px";
}
.card_main {
  height: 150px;
}
/* .card:hover {
padding:2px;
} */

.card_box-ad{
  padding: 20px;
  margin: 10px;
  height: 61px;
  background-color: #3b4a85;
  box-shadow: 0 0 15px #878686;
  box-sizing: border-box;  
  -moz-box-sizing: border-box;  
  -webkit-box-sizing: border-box;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  /* text-shadow: 0px 2px 0px #e638c0;  */
}
/* .card_box-ad:hover{
  transform: scale(1.1);
  box-shadow: 0 0 15px #3d3d3c;
  border: 5px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(to left, #743ad5, #FF0057);
} */