.tableempdetail{
    width: 100%;
    border: 2px solid;
    font-size: medium;
}
.tableth{
    width: 35%;
    border: 1px solid;
}
.tabletd{
    width: 65%;
    text-align: center;
    border: 1px solid;
}
.tableworkdetail{
    width: 100%;
    border: 2px solid;
    font-size: medium;
}
.tableworkth{
    width: 40%;
    border: 1px solid;
}
.tableworktd{
    width: 10%;
    text-align: center;
    border: 1px solid;
}
.tablereviewdetail{
    width: 100%;
    border: 2px solid;
    font-size: medium;
}
.tablereviewth{
   width: 80%;
    
    border: 1px solid;
}
.tablereviewtd{
    text-align: center;
    border: 1px solid;
}


.form-control:focus{
    border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
    
    
    display: block;
    background: url(https://mywildalberta.ca/images/GFX-MWA-Parks-Reservations.png) no-repeat;
    width: 20px;
    height: 20px;
    border-width: thin;
} */