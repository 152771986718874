.userclient strong {
  color: #f47321;
}
.clndrit p {
  color: #354c9e;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 0;
}
.clndrit date {
  color: #707070;
  font-size: 13px;
}
.clndr_item {
  align-items: center;
}

.profileDropSec button img {
  width: 43px;
}
.profileDropSec button {
  background-color: unset;
  margin-left: 8px;
}
.btnDashItems .btn-border:focus-within,
.btnDashItems .btn-border:hover,
.btnDashItems .btn-border.active {
  border: 1px solid #354c9e;
  background-color: #354c9e;
  transition: ease-in 0.3s;
  color: #ffffff;
}
.btnDashItems .btn-border {
  border: 1px solid #8f8f8f;
  transition: ease-in 0.3s;
  width: 157px;
  height: 36px;
  border-radius: 4px;
  color: #8f8f8f;
}
.dashBtns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}

.employee_score {
  background-color: #fafafa80;
  padding: 15px;
  margin: 15px 0;
  border-radius: 7px;
}
.boxItmsGraph {
  background-color: #ffffff;
  padding: 15px 30px;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 1px 15px;
  border-radius: 7px;
  height: 100%;
  position: relative;
}
.graphDesign img {
  width: 100%;
}
.headgraphs h1 {
  font-size: 40px;
  font-weight: 600;
  color: #f47321;
}

.percntgraph h5 {
  font-weight: 400;
  font-size: 15px;
  color: #646d82;
}
.percntgraph .prtyke {
  font-weight: 600;
  font-size: 15px;
  color: #354c9e;
}

.grphBtn {
  background-color: #354c9e;
  color: #fff;
  border: 1px solid #354c9e;
  border-radius: 50px;
  width: 150px;
  height: 40px;
}
.boxItmsGraph > p {
  font-size: 15px;
  font-weight: 700;
}
.nourimtry {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.voulumtry {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 12px;
}

.voulumtry p {
  display: flex;
  align-items: center;
  gap: 8px;
}
.vougray {
  background-color: #e4e4e4;
}
.voublue {
  background-color: #354c9e;
}
.voulumtry span.vougreen {
  background-color: green;
}
.voulumtry span.voured {
  background-color: red;
}
.voulumtry span {
  width: 12px;
  border-radius: 2px;
  height: 12px;
  display: block;
}
.tagNamer {
  position: absolute;
  left: -44px;
  bottom: 40%;
  background-image: linear-gradient(to right, #eb7c45, #ebae90);
  font-size: 19px;
  color: #ffff;
  width: 90px;
  border-radius: 5px;
  text-align: center;
  transform: rotate(270deg);
}
h1.numbertrsa {
  color: #354c9e;
}

.lengthItems{
  background-color: #F7F7F7;
  padding: 16px;
  border-radius: 15px;
}

.profileDropSec .dropdown-menu li a:focus-within,
.profileDropSec .dropdown-menu li a:hover{
  background-color: #e9ecef;
  transition: ease .3s;
}
.profileDropSec .dropdown-menu li a{
  color: #000000;
  width: 100%;
  transition: ease .3s;
  display: block;
  padding: 8px 10px;
}
.profileDropSec .dropdown-menu li{
  font-weight: 500;
  font-size: 11px;
}
.profileDropSec .dropdown-menu {
  transform: translate3d(-85px, 45px, 0px) !important;
  font-size: 12px;
}

.graphDesign.graphDesign3 {
  width: 80px;
}
.graphDesign.graphDesign4 {
  width: 106px;
}
.graphDesign.graphDesign5{
  width: 200px;
}

@media (max-width: 767px)  {
  .employee_score .row>div{
    margin-bottom: 20px;
  }
  .employee_score p{
    font-size: 13px !important;
  }
}