body{background: #fafafa;}

.f-14{

  font-size: 14px;
}

.border-left-body{

border-left: 3px solid #20409A;

}

.position-head-text{position:absolute; text-align: center;}

.btn-theme{

/* padding: 7px 10px; */
width: 355px;

border-radius: 30px;

color: #fff;

background: linear-gradient(

45deg

, #dc3739, #f68387);

/* border-color: #ae241d; */

font-weight: 600;

box-shadow: 0px 1px 6px #0000002b;

text-shadow: 0px 1px 6px #00000036;

}



#slider2 .rs-handle {

background-color: #f3f3f3;

box-shadow: 0px 0px 4px 0px #000;

}

#slider2 .rs-tooltip-text {



font-size: 25px;

font-weight: bold;

margin-top: 20px !important;

}

.bg-gradient{

background: linear-gradient(45deg, #27599b, #5f9ec5);

}

.half-part{



min-height:300px;

}

​

.card-border-survey{ box-shadow: 0px 1px 6px #00000012;}

.text-navy-blue{color: navy;}

.main-container{

min-height: 100vh;

display: flex;

align-items: center;

justify-content: center;

box-shadow: 0px 1px 6px #00000012;

}

.main-container .container{

padding: 0px;

box-shadow: 0 10px 50px 0 rgb(0 0 0 / 10%);



}

@media(max-width: 768px){

.half-part{


min-height:calc(50vh - 28px);



}

.main-container{

min-height: none;

display: block;

}

.main-container .container{

padding: 0px;

}

}