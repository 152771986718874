
.font14 {
    font-size: 14px;
}


/* width */
.scrl::-webkit-scrollbar {
width: 5px;
}

/* Track */
.scrl::-webkit-scrollbar-track {
background: #f3f3f3;
}

/* Handle */
.scrl::-webkit-scrollbar-thumb {
background: rgb(218, 218, 218);
}

/* Handle on hover */
.scrl::-webkit-scrollbar-thumb:hover {
background: rgb(170, 170, 170);
}

