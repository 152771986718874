// Here you can add other styles
.required-field {
    color: red;
}
.error-msg {
    color: red; 
}
.att-options ul li {
    color: #000;
    cursor: pointer;
    font-size: 16px;
}