.panel-info .content span{
    color: #727E8C;
    font-size: 16px;
}
.panel-info .content p{
    color: #F47321;
    font-size: 21px;
    font-weight: 700;
}
.panel-info .img-Bg{
    background-color: #ECECEC;
    padding: 11px;
    border-radius: 4px;
}
.panel-info{
    border-radius: 6px;
}
.hireGraph p{
color: #000;
}
.hireGraph .subtext{
font-size: 12px;
color: #354C9E;

}
.blueBx{
    background-color: #354C9E;
}
.contentBox{
text-align: center;
padding: 30px;
}
.contentBox2{
    text-align: center;
    padding: 6px;
    color: #535252;
    }
.OrangeBx{
    white-space: nowrap;
    background-color: #EB7C45;
}
.pinkBx{
    background-color: #EC6666;
}
.GreenBx{
    background-color: #A4C45D;
}
.DarkGreenBx{
background-color: #1B9C8D;
}
.contentBox{
font-size: 20px;
}
.btn-panel{
    border: 1px solid #8f8f8f;
    border-radius: 4px;
    color: #8f8f8f;
    padding: 5px;
}
.btnDashItems .btn-panel.active{
    border: 1px solid #354c9e;
    background-color: #354c9e;
    transition: ease-in 0.3s;
    color: #ffffff;
}
@media (min-width: 1200px) and (max-width: 1480px) {
.img-Bg img{
    width: 30px;
    height: 30px;
}
.panel-info .detail{
padding: 12px !important;
}
.panel-info .content span {
    font-size: 14px;
}

}
@media (min-width: 768px) and (max-width:1200px){
    .main-panel {
        margin-right: 12px ;
    }
}
.padding-0{
    padding-right:0;
    padding-left:0;
}