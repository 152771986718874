/* .marquree-line{
    width: max-content;
    padding:10px;
    margin:10px;
    background-color: #eee;
    color: black;
    border: 3px solid;
} */

/* border-image-source: linear-gradient(to left, #743ad5, #FF0057); */

    /* border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source: linear-gradient(to left, #743ad5, #FF0057);   */

/* .marquree-line{
    background-color: #eee;
    min-height: 30px;
} */

/* padding: 10px;
    margin: 10px;
    border: 1px solid;
    border-image-slice: 2;
    border-width: 3px;
    border-image-source: linear-gradient(to left, #743ad5, #FF0057);
    border-radius: 10%;
    color: white; */

.marquree-line{
    width: max-content;
    min-height: 50px;
    /* box-shadow: 5px 10px 8px 10px #888888; */
    
}
.text-time{
    font-size: 11px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: blueviolet;
    
}
.text-time1{
    font-size: 24px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(48, 48, 48);
    
}

.meeting-not{
    width: 70%;
    padding: 7px;
    margin: 7px;
    text-align: center;
    background-color: #eee;
    border-radius: 10px;
    font-size: 15px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    
}