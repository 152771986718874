
/**USE CSS FOR TASKBOX PAGE**/
.mainTabGroup {
    border: none;
    margin: -10px;
    padding-bottom: 30px;
}

.mainTabGroup .nav-item {
    margin: 0;
    padding: 10px;
    width: 25%;
}

.mainTabGroup .nav-item .nav-link {
    border: 1px solid #DCDCDC;
    background: #F7F7F7;
    padding: 10px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    height: 100%;
    opacity: .6;
}

.mainTabGroup .nav-item .nav-link.active {
    border: 1px solid #00468F;
    background: #00468F !important;
    color: #fff;
    opacity: 1;
}

.iconGroup {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconGroup img {
    width: 27px;
}

.txtGroup {
    width: calc(100% - 50px);
    padding-left: 14px;
}

.txtGroup strong {
    display: block;
    font-weight: 500;
    font-size: 14px;
    color: #00376A;
}
.txtGroup > div {
    gap: 15px;
}
.txtGroup b {
    display: block;
    font-weight: 900;
    font-size: 15px;
    color: #00376A;
}

.txtGroup span {
    display: block;
    font-size: 12px;
    color: #000000;
}

.mainTabGroup .nav-link.active .txtGroup b,
.mainTabGroup .nav-link.active .txtGroup strong,
.mainTabGroup .nav-link.active .txtGroup span {
    color: #fff;
}

.innerTab {
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 20px;
    gap: 20px;
}

.innerTab.nav-tabs .nav-item {
    margin: 0;
}

.innerTab.nav-tabs .nav-link {
    border: none;
    margin: 0;
    font-size: 14px;
    color: #B0B0B0 !important;
    text-transform: uppercase;
    font-weight: 600 !important;
    padding: 0 15px 13px;
    position: relative;
    background: none !important;
}

.innerTab.nav-tabs .nav-link:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #00468F !important;
    border-radius: 100px;
    opacity: 0;
}

.innerTab.nav-tabs .nav-link.active {
    color: #00468F !important
}

.innerTab.nav-tabs .nav-link.active:after {
    opacity: 01;
}

.profileDiv img {
    height: 30px;
    width: 30px;
    border-radius: 100px;
    object-fit: cover;

}

.taskTable .taskGroup {
    margin-bottom: 14px;

    display: block;
    border-radius: 4px;
    align-items: center;
}

.taskTable .taskGroup>div {
    padding: 15px;
    flex: 1 0 0%;
    white-space: nowrap;
}

.taskTable p {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: bold;
}

.taskTable strong {
    display: block;
    font-size: 14px;
    font-weight: bold;
}

.taskTable span {
    display: block;
    font-size: 13px;
    color: #00468F;
}

.profileDiv {
    font-size: 13px;
    align-items: center;
    gap: 10px;
    font-weight: normal;
    color: #00468F;
}

.profileDiv b {
    font-weight: normal;
}

.blueBtn {
    background: #00468F;
    border: 1px solid #00468F;
    padding: 8px 25px;
    font-size: 14px;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    outline: none !important;
    transition: 0.5s all;
    display: inline-block;
}

.blueBtn:hover {
    background: #003b7a;
    border: 1px solid #003b7a;
    color: #fff;
}
.taskTable{
    overflow: visible;
    overflow-x: auto;
    min-height: 195px;
}
.taskTable table {
    border-spacing: 0;
    margin: 0;
}

.taskTable .table>tr,
.taskTable .table>tbody>tr{
    background: none !important;
}
.taskTable .table>tr>td,
.taskTable .table>tbody>tr>td {
    vertical-align: middle;
    padding: 0 0 20px !important;
    border: none;
    background: none;
}

.taskTable .table tr td table tr {

    border: 1px solid #DCDCDC;
    vertical-align: middle;

}

.taskTable .table tr td table tr td {
    vertical-align: middle;
    border-radius: 4px;
    /* overflow: hidden; */
    background: #F7F7F7;
    white-space: nowrap;
}

.notifyAlrt {
    background: #DEFFDD;
    border: 1px solid #73D371;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
}

.notifyAlrt p {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    display:flex;
    align-items: center;
}

.notifyAlrt .blueBtn {
    margin-left: auto;
}

@media (min-width:992px) and (max-width:1199px) {
    .iconGroup {
        width: 40px;
        height: 40px;
    }
    .txtGroup {
        width: calc(100% - 40px);
        padding-left: 10px;
    }
}
@media (max-width:991px){
    .mainTabGroup {
        border: none;
        margin: -10px -10px 10px;
        padding-bottom: 0;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
        overflow-x: auto;
    }
    .mainTabGroup .nav-item{
        width: auto;
    }
    .taskTable p{
        width: 400px;
        white-space: normal;
    }
}
@media (max-width:767px){
    .notifyAlrt{
        flex-wrap: wrap;
    }
    .notifyAlrt .blueBtn {
        margin: auto;
    }
    .innerTab.nav-tabs .nav-link{
        font-size: 13px;
        padding: 0 10px 10px;

    }
}

/**USE CSS FOR TASKBOX PAGE END**/