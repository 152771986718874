

.meeting_room_booking_img {
  background-color: black;
    
  background-size: cover;
    /* width: 300px;
    height: 200px; */
    /* width: 100%; */
    min-height: 625px;
    /* margin: 10px 0 0 10px; */
    position: relative;
    background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  min-height: calc(100vh - 0px);
  display: flex;
  align-items: normal;
  flex-direction: column;
  justify-content: space-evenly;
  }
  
  .form_section {
    margin: auto;
    width: 400px;
  }
  .booking_form_section{
    
    margin:auto;
    width:80%;
    max-width: 500px;
  
  }
  .my-button-hide{
    appearance: none;
  }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  appearance: none;
}

.my-check{
  margin-top: 30px;
  color: white;
}
.sidenav_1 {
      min-height: calc(100vh - 0px);
      height: 100%;
      width: 20%;
      position: fixed; 
      z-index: 1; 
      top: 0; 

      right: 0;
      background: linear-gradient(#ff3399, #333399); 
      /* background-color:#463c3c;  */
      transition: 0.3s; 
      overflow-x: hidden; 
      padding-top: 20px; 
      padding-bottom: 27px; 
      display: flex; 
      flex-direction: column; 
      align-items: center;
}
.sidenav_11 {
  height: 100%;
  width: 5%;
  position: fixed; 
  z-index: 1; 
  top: 0; 
  right: 0; 
  /* background-color:#463c3c;  */
  transition: 0.3s; 
  overflow-x: hidden; 
  padding-top: 20px; 
  display: flex; 
  flex-direction: column; 
  align-items: center;
}
.button-room{
  background: linear-gradient(#ff3399, #333399);
  font-weight: bold;
      /* background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%); */
      /* background-color: #ff3399,#333399; */
      min-height: 60px;
}


/*  deepak css */

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav_1 {padding-top: 15px;}
  .sidenav_1 {font-size: 18px;}
}

@media(max-width:768px){
  .booking_form_section{
    
    margin:auto;
    padding: 0;
    width:100%;
    max-width: 500px;
  }
  .mediaClassForHeading{
    margin-top: 13%;
  }
  .sidenav_11 { width:100%;}
  .sidenav_1 { width:100%;}
}
/* .mediaClassForHeading{
 margin-top: 3%; 
} */