/* body{
    margin: 0;
    padding: 0;
}

.up-head{
   width:100%;
   background-color: rgb(245, 133, 6);
}
.up-head-f{
 width: 100%;
 min-height: 100px;
 background-color: white;
}  
.button{
   width: 350px;
   height: 65px;
   color: white;
   background-color: rgb(185, 105, 13);
}
.button-f{
background-color: #eeeeee;
width: 250px;
padding: 10px;
color: darkslategrey;;
min-height: 30px;
float: right;
margin-right: 13px;
border: 10px solid;
border-image-slice: 1;
border-width: 3px;
border-image-source: linear-gradient(to left, #354b9c, #e62e8e);
;
}
.text-time{
   padding: 5px;
}
.footer{
   width: 100%;
   
}

.r-content{
    padding: 30px;
}
h3{
    color: white;
    padding-bottom: 10px;
}
/* h2{
    color: white;    
} */ 

.custome-container{
    min-height: calc(100vh - 92px);
    display: flex;
    align-items: normal;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 20px 50px;
    
}
.bg-success-color{
  /* background-color: rgb(245, 133, 6); */
}
.pad-20{
  padding: 20px;
}
.bg-trans{
  background: #00000012;
  /* background-color: #b5100f; */
}
.img{
  max-width: 100%;
}
.img-qr{
  max-width: 200px;
  width: 100%;
}

/* ___________time______________________ */
.clock {
  background: linear-gradient(#ff3399, #333399);
  /* background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%); */
  /* background-color: #ff3399,#333399; */
  font-size: x-large;
  font-weight: 700;
}
