.card_box {
cursor: pointer;
  /* border: 2px solid red; */
  /* padding: 10px; */
  
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
.card_height {

  background-color:"#ADD8E6";
   color:"white"; 
   height:"80px";
}
.card_main {
  height: 150px;
}
/* .card:hover {
padding:2px;
} */